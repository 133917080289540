<template>
  <div :class="bem()">
    <template v-if="globalNotificationsStore.notifications.length">
      <template v-for="notification in globalNotificationsStore.notifications" :key="notification.id">
        <AppToast :notification="notification" />
      </template>
    </template>
  </div>
</template>

<script lang="ts" setup>
  import AppToast from '@/components/AppToast/AppToast.vue';
  import { useBEM } from '@/hooks/use-bem.hook';
  import { useGlobalNotificationsStore } from '@/store/global-notifications/global-notifications.store';

  const bem = useBEM('AppNotificationsContainer');
  const globalNotificationsStore = useGlobalNotificationsStore();
</script>

<style lang="scss" scoped>
  .AppNotificationsContainer {
    max-width: var(--page-max-width);
    margin: 0 auto;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 10000;

    .toast {
      position: absolute;
      top: 10rem;
      right: 2rem;
      border-radius: 0.5rem;
    }
  }
</style>

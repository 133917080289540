import App from '@/App.vue';
import { getAuth0Vue } from '@/auth0-vue';
import config from '@/config';
import '@/css/index.scss';
import vuetify from '@/plugins/vuetify';
import 'focus-visible';
import { createPinia } from 'pinia';
import * as Swiper from 'swiper/element/bundle';
import { createApp } from 'vue';
import { ForbiddenException, tryExtractingException } from './hooks/use-error-handling.hook';
import { monkeyPatchFetch } from './monkey-patch-fetch';
import router from './router';
import { RoutePath } from './router/types';
import { useI18nStore } from './store/i18n/i18n.store';
import { Language } from './types/language.enum';
import { NodeEnv } from './types/node-env.enum';

Swiper.register();

async function bootstrap() {
  const app = createApp(App);

  app.use(vuetify);
  app.use(createPinia());

  await useI18nStore().setLanguage(Language.EN);

  if (process.env.NODE_ENV !== NodeEnv.TESTING) {
    const auth0Instance = await getAuth0Vue();
    if (auth0Instance === null) return;

    const auth0 = auth0Instance.createAuth0(
      {
        domain: config.domain,
        clientId: config.clientId,
        useRefreshTokens: true,
        cacheLocation: 'localstorage',
        authorizationParams: {
          redirect_uri: window.location.origin,
          scope: 'openid profile email refresh_token offline_access',
          audience: config.audience,
        },
        leeway: 120,
      },
      { errorPath: RoutePath.NOT_AUTHORIZED },
    );

    app.use(auth0);

    monkeyPatchFetch({
      onResponse: async (response, resource, config) => {
        const exception = await tryExtractingException(response);

        if (!exception) {
          return;
        }

        if (exception instanceof ForbiddenException) {
          // Obtain a new token and replay the request externally
          const newToken = await auth0.getAccessTokenSilently({ cacheMode: 'off' });

          // Create a new config with the updated Authorization header
          const updatedConfig: RequestInit = {
            ...config,
            headers: {
              ...config?.headers,
              Authorization: `Bearer ${newToken}`,
            },
          };

          // Replay the original request with the new token
          const newResponse = await fetch(resource, updatedConfig);
          // Handle the new response as needed (e.g., process or log it)
          return newResponse;
        }
      },
    });
  } else {
    // https://mswjs.io/
    const { worker } = await import('./mocks/browser');
    worker.start({ onUnhandledRequest: 'bypass' });
  }

  app.use(router);
  app.mount('#app');
}

bootstrap();

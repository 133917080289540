import type { UserEvent } from '@/types/models/user-event.interface';
import { deviceDetect, isMobileOnly, isTablet } from 'mobile-device-detect';

export const constructUserEvent = (sessionId: string, event: string, path: string, referrer: string): UserEvent => {
  const { browserName, osName, isBrowser } = deviceDetect();
  const isTouchDevice = 'ontouchstart' in document.documentElement;
  const supportsWebSockets = 'WebSocket' in window || 'MozWebSocket' in window;
  const userLang = navigator.language;

  return {
    type: 'browser',
    time: new Date().toISOString(),
    sessionId,
    event,
    path,
    referrer,
    device: isMobileOnly ? 'mobile' : isTablet ? 'tablet' : isBrowser ? 'desktop' : 'unknown',
    os: osName,
    browser: browserName,
    screen: {
      width: window.screen.width,
      height: window.screen.height,
      dppx: window.devicePixelRatio,
    },
    viewport: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    features: {
      fileSlice: CSS.supports('display', 'grid'),
      touch: isTouchDevice,
      websocket: supportsWebSockets,
    },
    language: userLang,
  };
};

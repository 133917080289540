import * as API from '@/api/api';
import { DEFAULT_USER_SETTINGS_STATE } from '@/constants';
import { HTTPException } from '@/hooks/use-error-handling.hook';
import type { UserSettingsState } from '@/types/models/user-settings-state.interface';
import type { UserSettings } from '@/types/models/user-settings.interface';
import { defineStore } from 'pinia';
import type { UserSettingsStoreActions, UserSettingsStoreState } from './user-settings-store.types';

export const useUserSettingsStore = defineStore<
  'userSettings',
  UserSettingsStoreState,
  Record<string, never>,
  UserSettingsStoreActions
>('userSettings', {
  state: () => ({
    state: {
      tableExcludedArticlesIds: DEFAULT_USER_SETTINGS_STATE.tableExcludedArticlesIds,
      tablePinnedColumns: DEFAULT_USER_SETTINGS_STATE.tablePinnedColumns,
      tableHiddenColumns: DEFAULT_USER_SETTINGS_STATE.tableHiddenColumns,
      tableOrderedColumns: DEFAULT_USER_SETTINGS_STATE.tableOrderedColumns,
      tableArticleStatusValues: DEFAULT_USER_SETTINGS_STATE.tableArticleStatusValues,
      tableCCLValues: DEFAULT_USER_SETTINGS_STATE.tableCCLValues,
      tableStyleGroupValues: DEFAULT_USER_SETTINGS_STATE.tableStyleGroupValues,
      tablePriceLevelValues: DEFAULT_USER_SETTINGS_STATE.tablePriceLevelValues,
      tableProductNameValues: DEFAULT_USER_SETTINGS_STATE.tableProductNameValues,
      tablePage: DEFAULT_USER_SETTINGS_STATE.tablePage,
      tableItemsPerPage: DEFAULT_USER_SETTINGS_STATE.tableItemsPerPage,
      tableOrderBy: DEFAULT_USER_SETTINGS_STATE.tableOrderBy,
      tableDesc: DEFAULT_USER_SETTINGS_STATE.tableDesc,
      tableSearchColumn: DEFAULT_USER_SETTINGS_STATE.tableSearchColumn,
      tableSearchValue: DEFAULT_USER_SETTINGS_STATE.tableSearchValue,
      tableColoring: DEFAULT_USER_SETTINGS_STATE.tableColoring,
    },
  }),
  actions: {
    async createSettings(state): Promise<UserSettings> {
      Object.assign(this.state, state);

      return API.createUserSettings({
        state: JSON.stringify(this.state),
      });
    },

    async updateSettings(state): Promise<UserSettings> {
      Object.assign(this.state, state);

      return API.updateUserSettings({
        state: JSON.stringify(this.state),
      });
    },

    async fetchSettings(): Promise<void> {
      let settings: UserSettings;

      try {
        settings = await API.getUserSettings();
      } catch (err) {
        if (err instanceof HTTPException && err.status === 404) {
          settings = await this.createSettings(this.state);
        } else {
          console.error(err);
          return;
        }
      }

      const state: UserSettingsState = JSON.parse(settings.state);

      this.state.tableExcludedArticlesIds =
        state.tableExcludedArticlesIds ?? DEFAULT_USER_SETTINGS_STATE.tableExcludedArticlesIds;
      this.state.tablePinnedColumns = state.tablePinnedColumns ?? DEFAULT_USER_SETTINGS_STATE.tablePinnedColumns;
      this.state.tableHiddenColumns = state.tableHiddenColumns ?? DEFAULT_USER_SETTINGS_STATE.tableHiddenColumns;
      this.state.tableOrderedColumns = state.tableOrderedColumns ?? DEFAULT_USER_SETTINGS_STATE.tableOrderedColumns;
      this.state.tableArticleStatusValues =
        state.tableArticleStatusValues ?? DEFAULT_USER_SETTINGS_STATE.tableArticleStatusValues;
      this.state.tableCCLValues = state.tableCCLValues ?? DEFAULT_USER_SETTINGS_STATE.tableCCLValues;
      this.state.tableStyleGroupValues =
        state.tableStyleGroupValues ?? DEFAULT_USER_SETTINGS_STATE.tableStyleGroupValues;
      this.state.tablePriceLevelValues =
        state.tablePriceLevelValues ?? DEFAULT_USER_SETTINGS_STATE.tablePriceLevelValues;
      this.state.tableProductNameValues =
        state.tableProductNameValues ?? DEFAULT_USER_SETTINGS_STATE.tableProductNameValues;
      this.state.tablePage = state.tablePage ?? DEFAULT_USER_SETTINGS_STATE.tablePage;
      this.state.tableItemsPerPage = state.tableItemsPerPage ?? DEFAULT_USER_SETTINGS_STATE.tableItemsPerPage;
      this.state.tableOrderBy = state.tableOrderBy ?? DEFAULT_USER_SETTINGS_STATE.tableOrderBy;
      this.state.tableDesc = state.tableDesc ?? DEFAULT_USER_SETTINGS_STATE.tableDesc;
      this.state.tableSearchColumn = state.tableSearchColumn ?? DEFAULT_USER_SETTINGS_STATE.tableSearchColumn;
      this.state.tableSearchValue = state.tableSearchValue ?? DEFAULT_USER_SETTINGS_STATE.tableSearchValue;
      this.state.tableColoring = state.tableColoring ?? DEFAULT_USER_SETTINGS_STATE.tableColoring;
    },
  },
});
